<template>
  <div class="custom-container">
    <div class="card-container">
        <div class="heading">Forgot Password?</div>
        <div class="para">
            Enter your email and we'll send you instructions to reset your password.
        </div>
        <div class="input-container d-flex flex-column mb-3">
        <label for="">Email</label>
        <input
          type="email"
          name=""
          id=""
          placeholder="Enter your email"
          v-model="email"
        />
      </div>
      <button class="send-mail w-100 my-4 mt-2 mb-4 py-2 rounded" @click="useForgotPwd()">Send Reset Link</button>
      <div class="text-center">
         Back to <router-link to="/login">Login</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {forgotPwd} from '../utils/apis'
export default {
    data() {
        return {
            email: '',
        }
    },
    methods:{
      async useForgotPwd(){
      try {
        let res= await forgotPwd({email: this.email})
        this.$toasted.success("Please check your mail to reset password.", {
          position: "top-center",
          action: {
            text: "OK",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
          this.$router.push({ name: 'Login' })
      } catch (error) {
        this.$toasted.error("Email not registered.", {
          position: "top-center",
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }
    }
    }
};
</script>
<style lang="scss" scoped>
.custom-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
.card-container {
  width: 27rem;
  min-height: 25rem;
  max-width: 90%;
  background-color: #fff;
  box-shadow: 0px 10px 30px -3px rgba($color: #000000, $alpha: 0.1);
  border-radius: 10px;
  padding: 4rem 2rem 2rem 2rem;
  font-family: "Montserrat", sans-serif;
}
.heading {
  font-weight: 500;
  font-size: 1.125rem;
  color: #5e5873;
  margin-bottom: 1rem;
}
.para {
  font-size: 0.9rem;
  font-weight: 300;
}
.input-container {
  margin-top: 1rem;
  label {
    font-size: 0.9rem;
  }
  input {
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    transition: 0.3s ease;
    &:focus,
    &:active {
      outline: none;
      border: 1px solid #928da7;
    }
  }
}
.send-mail {
  color: #fff;
  background-color: #4256ee;
  border: none;
}
</style>
