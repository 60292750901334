<template>
    <div class="w-100">
        <NavbarCommon />
        <NavbarSmall />
        <div class="w-100 d-flex position-relative">
            <div class="custom-left">
                <div class="custom-tab-item" @click="setSelectedReservationType($event,'upcoming')">
                    <div class="block">
                        Icon
                    </div>
                    <div class="text">
                        Upcoming
                    </div>
                </div>
                <div class="custom-tab-item active"  @click="setSelectedReservationType($event,'all')">
                    <div class="block">
                        Icon
                    </div>
                    <div class="text" >
                        All Bookings
                    </div>
                </div>
                <div class="custom-tab-item"  @click="setSelectedReservationType($event,'cancelled')">
                    <div class="block">
                        Icon
                    </div>
                    <div class="text">
                        Cancelled Bookings
                    </div>
                </div>
                <div class="custom-tab-item"  @click="setSelectedReservationType($event,'visited')">
                    <div class="block">
                        Icon
                    </div>
                    <div class="text">
                        All Visited 
                    </div>
                </div>
            </div>
            <div class="custom-right p-4">
                <div class="p-2 mb-4">
                    <span style="font-size:20px; font-weight:bold;">
                        {{selectedReservationType === "upcoming" ? "Upcoming" : selectedReservationType === "all" ? "All Bookings" : selectedReservationType === "cancelled" ? "Cancelled Bookings" : "All Visited"}}
                    </span>
                </div>
                <div class="d-flex justify-content-start flex-wrap">
                    <div class="d-flex custom-w-25 justify-content-around" style="margin-bottom:3rem" v-for="(item,index) in selectedReservationItems" :key="index">
                        <ReserveItem :reservation="item"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ReserveItem from '@/components/ReserveItem/ReserveItem.vue'
import NavbarCommon from "@/components/NavbarCommon/NavbarCommon.vue";
import NavbarSmall from "@/components/NavbarSmall/NavbarSmall.vue";
import { getRentersBookings } from '@/utils/apis.js';

export default {
    data(){
        return{
            reservations:[
                {
                    start_date: '19 Jan 22',
                    end_date: '24 Jan 22',
                    property_name: "Mahipal's property",
                    property_location: '56, Mahipal, Mumbai',
                    property_id: 234,
                    guests: {
                        adults: 8,
                        children: 1,
                    },
                    rooms:1,
                    type:"upcoming",
                    price:20000,
                },
                {
                    start_date: '19 Jan 22',
                    end_date: '24 Jan 22',
                    property_name: "Shivani's property",
                    property_location: '56, Shivani, Mumbai',
                    property_id: 234,
                    guests: {
                        adults: 8,
                        children: 1,
                    },
                    rooms:1,
                    type:"cancelled",
                    price:20000,
                },
                {
                    start_date: '19 Jan 22',
                    end_date: '24 Jan 22',
                    property_name: "Sachin's property",
                    property_location: '56, Sachin, Mumbai',
                    property_id: 234,
                    guests: {
                        adults: 8,
                        children: 1,
                    },
                    rooms:1,
                    type:"visited",
                    price:4000,
                },
                {
                    start_date: '19 Jan 22',
                    end_date: '24 Jan 22',
                    property_name: "Mahipal's property",
                    property_location: '56, Mahipal, Mumbai',
                    property_id: 234,
                    guests: {
                        adults: 8,
                        children: 1,
                    },
                    rooms:1,
                    type:"upcoming",
                    price:20000,
                },
                {
                    start_date: '19 Jan 22',
                    end_date: '24 Jan 22',
                    property_name: "Mahipal's property",
                    property_location: '56, Mahipal, Mumbai',
                    property_id: 234,
                    guests: {
                        adults: 8,
                        children: 1,
                    },
                    rooms:1,
                    type:"upcoming",
                    price:20000,
                }
            ],
            selectedReservationType: "all",
            selectedReservationItems: [],
        }
    },
    components: {
        ReserveItem,
        NavbarCommon,
        NavbarSmall
    },
    methods:{
        setSelectedReservationType(e,type){
            e.preventDefault();
            this.selectedReservationType = type;
            let items = document.querySelectorAll(".custom-tab-item");
            items.forEach(item => {
                item.classList.remove("active");
            });
            if(e.target.classList.contains("custom-tab-item")){
                e.target.classList.add("active");
            }
            else{
                e.target.parentElement.classList.add("active");
            }
            if(type == "upcoming"){
                this.selectedReservationItems = this.reservations.filter(reservation => reservation.status == "upcoming");
            }
            else if(type == "cancelled"){
                this.selectedReservationItems = this.reservations.filter(reservation => reservation.status == "cancelled");
            }
            else if(type == "visited"){
                this.selectedReservationItems = this.reservations.filter(reservation => reservation.status == "visited");
            }
            else{
                this.selectedReservationItems = this.reservations;
            }
        },
        async useRentersFunctions(){
            try{
                let response = await getRentersBookings();
                console.log("**&&^",response.data.data);
                this.reservations = response.data.data;
                this.selectedReservationItems = [...this.reservations]
            }
            catch(error){
                console.log(error);
            }
        }
    },
    mounted() {
        // This is only for testing and visulization purpose. Please remove it when integrating the API
        this.useRentersFunctions();
    },
}
</script>
<style lang="scss" scoped>
    .custom-left{
        width: 10%;
        height: calc(100vh - 4rem);
        background-color: #F6F7FB;
        display: flex;
        align-items: center;
        padding-top: 4rem;
        flex-direction: column;
    }
    .custom-right{
        width: 90%;
        height: calc(100vh - 4rem);
        overflow: auto;
    }
    .custom-tab-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        cursor: pointer;
        transition: 0.3s ease;
        .block{
            height:3.2rem;
            width: 3.2rem;
            border-radius: 10px;
            background-color: #fff;
            color: #9BAAD3;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 20px 40px -5px rgba($color: #000000, $alpha: 0.1);
            margin-bottom: 1rem;
        }
        .text{
            font-size: 0.8rem;
            font-weight: 500;
            color: #9BAAD3;
        }
        &.active{
            .block{
                background-color: #3c3ce1;
                color: #fff;
                box-shadow: 0px 20px 40px -5px rgba($color: #3c3ce1, $alpha: 0.3);
            }
            .text{
                color: #1C295D;
            }
        }
    }
    .custom-w-25{
        width: 25%;
    }
    @media only screen and (max-width: 578px) {
        .custom-left{
            width:0% !important;
            display: none;
        }
        .custom-right{
            width: 100%;
            height: 100%;
        }
        .custom-tab-item{
            margin-bottom: 0;
        }
        .custom-w-25{
            width: 100% !important;
        }
    }
</style>