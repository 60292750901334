<template>
  <div class="about d-flex w-100 vh-100">
    <div class="custom-left"></div>
    <div class="custom-right" style="overflow:auto;">
      <div class="top-text">Get to know us</div>
      <div class="heading">About Us</div>
      <div class="text">
        Founded in 2019 and based in Bangalore and Singapore, JadeCaps is an
        online platform for short term rentals. This tech-enabled platform
        serves as second home management, rental and resale platform for
        property owners and provides short -term rentals to those looking for
        flexible leases. The platform has a variety of urban homes and vacation
        homes catering to millennials looking for alternative accommodation.
        JadeCaps simplifies the process of renting urban homes by offering
        flexible terms to renters and homeowners. Our full-stack tech solution
        ensures a great digital experience for renters with transparency and
        ease of transaction throughout the entire process. These urban spaces
        are rented by Expats , Business Travelers , Medical travelers & Locals
        looking for in-between homes JadeCaps also offers a collection of
        holiday homes for short stays. These are mostly set up as private
        holiday homes or farmhouses which is accessible for weekends and long
        stay based on availability. All the vacation homes come with
        full-stocked kitchen, high-speed broadband and unique amenities like
        private swimming pool or a jacuzzi. These holiday homes serves as great
        destination for weekend stays or long stay for work from home guests.
        Our brilliant team of property managers, photographers and marketing
        executives help provide an unparalleled service and great experience in
        over (number) of cities across India with rapidly expanding inventory of
        properties. Our focus on data driven sales ensures that property owners
        optimize the yield on residential assets. We also offer asset management
        product to vacation property owners by fractionalizing their homes and
        offering it to a wider set of investors. Vision India’s largest platform
        for short term rental assets Mission To organize this fragment space of
        short -term rental and offer full stack solution for second home
        ownership.
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.custom-left {
  width: 40%;
  height: 100%;
  background: url("../assets/about.jpg") no-repeat center center;
  background-size: cover;
}
.custom-right {
  width: 60%;
  height: 100%;
  padding: 4rem 6rem;
  .top-text {
    font-size: 1.2rem;
    color: #444bd3;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .heading {
    font-size: 3rem;
    font-weight: 700;
  }
  .text {
    font-size: 1.125rem;
    color: rgba(18, 17, 39, 0.56);
    margin-bottom: 1rem;
    line-height: 2;
  }
}
@media only screen and (max-width: 578px) {
  .custom-left{
    width: 0%;
    height: 0%;
    display: none;
  }
  .custom-right {
    width: 100%;
    padding: 2rem;
  }
}
</style>
