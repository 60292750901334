<template>
    <div class="w-100 vh-100">
        <div class="d-flex justify-content-center align-items-center flex-column h-100">
            <div class="circle position-relative mb-4">
                &#10005;
            </div>
            <div class="mb-2" style="font-size:40px;font-weight:400;">
                {{this.$route.query.msg}}
            </div>
            <div class="mb-4" style="text-align:center;font-size:24px;font-weight:400;color:#00000080;">
                Please check your email <br>
                more details. <br>
                
            </div>
            <div>
                <a class="custom-home-button" href="/">Go to home</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    .circle{
        width:4rem;
        height:4rem;
        border-radius:50%;
        background-color:#fd0d0d33;
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:1.2rem;
        color:#fd1d0d;
    }
    .custom-home-button{
        padding: 5px 10px;
        border-radius: 4px;
        background-color: royalblue;
        color: white;
        text-decoration: none;
        border:1px solid royalblue;
        transition: 0.3s ease;
        &:hover{
            background-color: #fff;
            color: royalblue;
        }
    }   
</style>
