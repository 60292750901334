<template>
  <div class="h-100">
    <NavbarCommon />
    <NavbarSmall />
    <div class="custom-container" v-if="!loginModal">
      <div class="heading">Booking Summary</div>
      <div
        class="
          custom-container-2
          d-flex
          justify-centent-center
          position-relative
          w-100
        "
      >
        <div class="w-50 position-relative py-2">
          <div class="custom-heading mb-3">
            {{ props.name }}
          </div>
          <!-- <div class="custom-line-2 mb-4" style="font-size: 22px">
            <span style="color: #3c3ce1; font-size: 30px">&#9733;</span>
            4.6(<span style="color: #74788d">12 Reviews</span>). Faridabad,
            Haryna, India
          </div> -->
          <div style="font-size: 25px; font-weight: 500" class="mb-2">
            Booking Details
          </div>
          <ul>
            <li class="mb-1">{{ this.props.no_of_guests }} guests</li>
            <li class="mb-1">
              Stay Type -
              <span style="color: #3c3ce1">{{
                props.stay ? "Short Stay" : "Long Stay"
              }}</span>
            </li>
            <li class="mb-1">
              <span style="color: #ea4335">Non-refundable</span> | On
              Cancellation zero refund is applicable
            </li>
          </ul>
          <div class="custom-checkin-checkout">
            <div
              style="color: #3c3ce1; cursor: pointer"
              class="d-flex justify-content-end"
            >
              <small @click="$router.go(-1)"> Modify Now</small>
            </div>
            <div
              class="d-flex justify-content-center mb-2"
              style="font-size: 24px"
            >
              Check In <span class="mx-4" style="color: #3c3ce1">|</span> Check
              Out
            </div>
            <div
              class="d-flex justify-content-center mb-2"
              style="font-size: 24px; color: rgba(0, 0, 0, 0.5)"
            >
              {{ props.check_in }} <span class="mx-4">&#8594;</span>
              {{ props.check_out }}
            </div>
          </div>
        </div>
        <div class="w-50 position-relative px-2 py-2">
          <div class="custom-card w-100 d-flex flex-column">
            <div class="card-top w-100 p-2">
              <strong style="font-size: 20px">Price Summary</strong>
            </div>
            <div
              class="card-headers mt-2 p-2 w-100 d-flex justify-content-between"
            >
              <div class="w-75" style="font-weight: 600">Item</div>
              <div class="w-25" style="font-weight: 600">Price</div>
            </div>
            <div
              class="line"
              style="height: 1px; width: 100%; background-color: #f5f6f8"
            ></div>
            <div class="mb-3">
              <div
                class="
                  card-Bottom
                  mt-2
                  px-2
                  w-100
                  d-flex
                  justify-content-between
                "
              >
                <div class="w-75" style="font-size: 20px; font-weight: 700">
                  1 Room *
                  {{
                    props.booking_type == "Short Stay"
                      ? props.total_nights
                      : props.total_months
                  }}
                  {{ props.booking_type == "Short Stay" ? "night" : "month" }}
                  {{
                    props.stay
                      ? null
                      : props.total_days
                      ? props.total_days + " night"
                      : null
                  }}
                </div>
                <div class="w-25" style="font-size: 20px">
                  ₹ {{ props.total_price }}
                </div>
              </div>
              <div class="px-2" style="color: #74788d">Price</div>
            </div>
            <!-- <div class="mb-3">
              <div
                class="
                  card-Bottom
                  mt-2
                  px-2
                  w-100
                  d-flex
                  justify-content-between
                "
              >
                <div class="w-75" style="font-size: 20px; font-weight: 700">
                  Taxes
                </div>
                <div class="w-25" style="font-size: 20px">
                  ₹ {{ 0.18 * props.amountFinal }}
                </div>
              </div>
              <div class="px-2" style="color: #74788d">GST + CGST</div>
            </div> -->
            <div class="mb-3">
              <div
                class="
                  card-Bottom
                  mt-2
                  px-2
                  w-100
                  d-flex
                  justify-content-between
                "
              >
                <div class="w-75" style="font-size: 20px; font-weight: 700">
                  Discount
                </div>
                <div class="w-25" style="font-size: 20px">₹ 0</div>
              </div>
              <div class="px-2" style="color: #74788d">GST + CGST</div>
            </div>
            <div
              class="
                card-end
                w-100
                p-2
                position-relative
                d-flex
                justify-content-between
              "
            >
              <div class="w-75" style="font-size: 20px; font-weight: 700">
                Total
              </div>
              <div class="w-25" style="font-size: 20px">
                ₹ {{ this.createPaymentBody.amount / 100 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="custom-important w-100 my-4">
        <div
          class="custom-imp-top w-100 h-50 p-2"
          style="color: #ff9e43; font-weight: 700"
        >
          Important! You might need these while checking in.
        </div>
        <div class="w-100 p-2 h-50">
          An ID Proof. (
          <span style="font-size: 12px">
            Passport, Aadhar, Driving License and Govt. ID are accepted as ID
            proof(s) </span
          >)
        </div>
      </div>

      <!-- ---------guest -->
      <div class="w-100 position-relative px-2 py-2">
        <div class="custom-card w-100 d-flex flex-column">
          <div class="card-top w-100 p-2">
            <strong style="font-size: 20px">Guest Details </strong>
          </div>
          <div class="custom-flex" v-for="(item, index) in guests" :key="item">
            <div
              class="card-headers mt-2 p-2 w-100 d-flex justify-content-between"
            >
              <div class="w-75" style="font-weight: 600">
                Guest {{ index + 1 }}
              </div>
            </div>
            <div
              class="line"
              style="height: 1px; width: 100%; background-color: #f5f6f8"
            ></div>
            <div
              class="card-Bottom mt-2 px-2 w-100 d-flex justify-content-between"
            >
              <div class="form">
                <div class="px-2" style="color: #74788d">Name</div>
                <input type="text" v-model="item.name" />
                <div
                  class="px-2"
                  style="color: red"
                  v-if="error.guests[index].name"
                >
                  {{ error.guests[index].name }}
                </div>
              </div>
              <div class="form">
                <div class="px-2" style="color: #74788d">Age</div>
                <input type="number" v-model="item.age" />
                <div
                  class="px-2"
                  style="color: red"
                  v-if="error.guests[index].age"
                >
                  {{ error.guests[index].age }}
                </div>
              </div>
              <div class="form">
                <div class="px-2" style="color: #74788d">Gender</div>
                <select name="gender" id="gender" v-model="item.gender">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
            </div>
          </div>
          <div class="guest-footer">
            <div @click="useCreatePayment" class="book-now-btn">Confirm</div>
          </div>
        </div>
      </div>
      <!-- end guest............. -->

      
      <div class="my-2">
        <div class="custom-card w-100 d-flex flex-column"></div>
      </div>
    </div>
    <div v-if="loginModal">
      <LoginModal :setLoginModal="setLoginModal" />
    </div>
  </div>
</template>
<script>
import {
  createPayment,
  paymentSuccess,
  addReservation,
  addGuest,
  getBookingSummary,
} from "@/utils/apis.js";
import NavbarCommon from "@/components/NavbarCommon/NavbarCommon.vue";
import NavbarSmall from "@/components/NavbarSmall/NavbarSmall.vue";
import LoginModal from "@/components/LoginModal/LoginModal.vue";

export default {
  data() {
    return {
      props: {},
      createPaymentBody: {},
      loginModal: false,
      guests: [],
      createReservationBody: {},
      transactionId: "",
      error: {
        guests: [],
        status: false,
      },
    };
  },
  methods: {
    async useGetBookingSummary() {
      try {
        let res = await getBookingSummary(parseInt(this.$route.query.houseId));
        this.props = res.data;
        for (let i = 0; i < parseInt(res.data.no_of_guests); i++) {
          this.guests.push({ name: "", age: "", gender: "male" });
          this.error.guests.push({ name: null, age: null, gender: null });
        }
        console.log(this.props);
        this.createPaymentBody = {
          // amount: Math.round(((1.18 * parseInt(this.props.amountFinal)) + Number.EPSILON) * 100) / 100,
          amount: parseInt(this.props.total_price * 100),
          currency: "INR",
          type_of_stay:
            this.props.booking_type == "Short Stay" ? "short" : "long",
          house_id: parseInt(this.$route.query.houseId),
        };
      } catch {
        this.$toasted.info("Please login first.", {
          position: "top-center",
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        if (!localStorage.getItem("app_access_token")) {
          this.$router.push({ name: "Login" });
          return;
        }
      }
    },
    validateGuest() {
      // this.error.guests=[];
      this.error.status = false;

      for (let i = 0; i < this.props.no_of_guests; i++) {
        this.error.guests[i].name = null;
        this.error.guests[i].age = null;
        if (
          this.guests[i].name.length < 1 ||
          this.guests[i].name == null ||
          this.guests[i].name == undefined
        ) {
          this.error.guests[i].name = "Please enter a valid name.";
          this.error.status = true;
          // return false;
        }
        if (
          this.guests[i].age.length < 1 ||
          this.guests[i].age == null ||
          this.guests[i].age == undefined
        ) {
          this.error.guests[i].age = "Please enter a valid age.";
          // return false;
          this.error.status = true;
        }
      }
      // return true;
    },
    async useCreatePayment() {
      this.validateGuest();
      if (this.error.status) {
        // alert(this.validateGuest())
        return false;
      }

      try {
        // console.log(this.guests)
        if (localStorage.getItem("app_access_token")) {
          let res = await createPayment(this.createPaymentBody);
          let self = this;
          self.transactionId = res.transaction_id;
          var options = {
            key: res.razorpay__key_id, // Enter the Key ID generated from the Dashboard
            amount: res.razorpay_amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: res.currency,
            name: "Jadecaps",
            description: "Test Transaction",
            order_id: res.razorpay_order_id,
            // image: "https://example.com/your_logo",
            handler: async function (response) {
              let success = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                transaction_id: res.transaction_id,
              };
              let succ = await paymentSuccess(success);
              if (succ.success) {
                let createReservationBody = {
                  payment_done: true,
                  transaction_id: res.transaction_id,
                  house_id: self.$route.query.houseId,
                  check_in: self.props.check_in,
                  check_out: self.props.check_out,
                  type:
                    self.props.booking_type == "Short Stay"
                      ? "daily"
                      : "monthly",
                  no_of_guests: self.props.no_of_guests,
                };

                try {
                  let resSuccess = await addReservation(createReservationBody);
                  console.log(resSuccess);
                  if (resSuccess.success) {
                    let addGuestApi = await addGuest(
                      { guests: self.guests },
                      resSuccess.id
                    );
                    console.log("Paneer", addGuestApi);
                    if (addGuestApi.success) {
                      self.$router.push({
                        name: "Confirmation",
                        params: { id: self.transactionId },
                      });
                    } else {
                      alert("Error One");
                      self.$router.push({
                        name: "PaymentFailed",
                        query: {
                          msg: "some error occured",
                        },
                      });
                    }
                  } else {
                    alert("Error Two");
                    self.$router.push({
                      name: "PaymentFailed",
                      query: {
                        msg: "Reservation unsuccessful",
                      },
                    });
                  }
                } catch (error) {
                  alert("Error Three");
                  self.$router.push({
                    name: "PaymentFailed",
                    query: {
                      msg: "Reservation unsuccessful",
                    },
                  });
                }
              } else {
                alert("Error Four");
                self.$router.push({
                  name: "PaymentFailed",
                  query: {
                    msg: "some error occured",
                  },
                });
              }
            },
            // prefill: {
            //   name: "Gaurav Kumar",
            //   email: "gaurav.kumar@example.com",
            //   contact: "9999999999",
            // },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };
          var rzp1 = new Razorpay(options);
          rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
            self.$router.push({
              name: "PaymentFailed",
              query: {
                msg: response.error.reason,
              },
            });
          });
          rzp1.open();
        } else {
          this.setLoginModal();
        }
      } catch (error) {
        alert(error);
        this.$toasted.error("Some error occured.", {
          position: "top-center",
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }
    },
    setLoginModal() {
      this.loginModal = !this.loginModal;
    },
  },
  created() {
    sessionStorage.setItem("routeChk", 1);
  },
  mounted() {
    this.useGetBookingSummary();

    window.scroll(0, 0);
  },
  props: ["date", "property", "stay", "amountFinal"],
  components: {
    NavbarCommon,
    LoginModal,
    NavbarSmall,
  },
};
</script>
<style lang="scss" scoped>
.blank-nav {
  height: 4rem;
  box-shadow: 0px 10px 30px -3px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1;
  left: 0;
  margin-bottom: 2rem;
  background: #fff;
}
.custom-container {
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.heading {
  font-size: 24px;
  margin-bottom: 3rem;
}
.form {
  width: 30%;
}
input,
select {
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  width: 100%;
}
.guest-footer {
  padding: 0 0.5rem;
  padding-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
.book-now-btn {
  width: 20%;
  background-color: blue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  cursor: pointer;
  height: 3.1rem;
}
.custom-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 1rem;
  // min-height: 30rem;
}
.card-top {
  background-color: #f5f6f8;
  border-radius: 5px;
}
.card-end {
  background-color: #f5f6f8;
  border-radius: 5px;
  margin-top: auto;
  color: #495057;
}
.custom-heading {
  font-size: 32px;
}
.custom-checkin-checkout {
  padding: 1rem;
  border: 2px solid #f5f6f8;
  border-radius: 5px;
  width: 60%;
}
.custom-important {
  background-color: #fff3e8;
  height: 5rem;
  position: relative;
  border-radius: 5px;
}
.custom-imp-top {
  background-color: #fff3e8;
  box-shadow: 0px 5px 20px -3px #ff9e4370;
}

@media only screen and (max-width: 550px) {
  .custom-container-2 {
    flex-direction: column;
    div {
      &:nth-child(1) {
        width: 100% !important;
      }
      &:nth-child(2) {
        width: 100% !important;
      }
      .custom-checkin-checkout {
        width: 100%;
      }
    }
  }
  .custom-flex {
    display: flex;
    flex-direction: column;
    .card-Bottom {
      flex-direction: column !important;
      .form {
        width: 100% !important;
      }
    }
  }
  .book-now-btn {
    width: 100%;
  }
}
</style>
