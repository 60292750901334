<template>
    <div class="custom-reserve-item-card">
        <div class="h-33 position-relative" style="border-bottom:1px solid rgba(0,0,0,0.1); border-radius: 1rem 1rem 0 0;">
            <div class="custom-date">
                {{reservation.start_date}} | {{reservation.end_date}}
            </div>
            <div class="px-3" style="padding-top:2rem; text-align:right; font-weight:bold; color:#4256EE; line-height:0.7;">
                ₹ {{reservation.price}}
            </div>
            <div class="px-3 mb-2" style="text-align:center;">
                {{reservation.property_name}}
            </div>
            <div class="d-flex justify-content-center align-items-center" style="font-size:12px; line-height:0;">
                <img src="../../assets/location_gray.png" style="height:13px;margin-right:0.3rem;">
                {{reservation.property_location}}
            </div>
        </div>
        <div class="h-33 position-relative p-2 d-flex" style="border-bottom:1px solid rgba(0,0,0,0.1);">
            <div class="w-25 h-100">
                <div class="">
                    <img src="../../assets/user_black.png" style="height:33px;">
                </div>
            </div>
            <div class="w-75 h-100 d-flex flex-column justify-content-between">
                <div style="font-size:20px;font-weight:bold;">Guests</div>
                <div class="d-flex">
                    <div style="font-size:18px;">{{reservation.guests}} Guests</div> 
                </div>
            </div>
        </div>
        <div class="h-33 position-relative p-2 d-flex" style="border-radius:0 0 1rem 1rem;">
            <div class="w-25 h-100">
                <div class="">
                    <img src="../../assets/curtains_black.png" style="height:23px;margin-left:5px;">
                </div>
            </div>
            <div class="w-75 h-100 d-flex flex-column justify-content-between">
                <div style="font-size:20px;font-weight:bold;">Price</div>
                <div class="d-flex">
                    <div style="font-size:18px;">₹ {{reservation.price}}</div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
           
        }

    },
    props:['reservation']
}
</script>

<style lang="scss" scoped>
.custom-reserve-item-card{
    width: 22rem;
    height: 20rem;
    border-radius: 1rem;
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    border: 1px solid rgba(0,0,0,0.1);
    .h-33{
        height: 33%;
        background-color: #fff;
        width: 100%;
    }
}
.custom-date{
    font-weight: bold;
    color: #fff;
    width: 70%;
    padding: .75rem 1rem;
    background: linear-gradient(to right, #005EFF, #57ACFB);
    border-radius: 3rem;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top:-1.3rem;
    z-index: 100;
}
@media only screen and (max-width: 578px) {
    .custom-reserve-item-card{
        width: 90% !important;
        height: 20rem;
        border-radius: 1rem;
        background-color: #fff;
        box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
        border: 1px solid rgba(0,0,0,0.1);
        .h-33{
            height: 33%;
            background-color: #fff;
            width: 100%;
        }
    }
}
</style>