<template>
    <div class="w-100 vh-100 d-flex justify-content-center align-items-center">
        <div class="custom-confirm-card">
            <div class="custom-header">Your rental has been confirmed.</div>
            <div class="custom-paragraph">Please check your email for trip confirmation and details.</div>
            <div class="custom-image">
                <img :src="RelaxImage" alt="">
            </div>
            <div class="custom-name">
                Property Name
            </div>
            <div class="custom-booking-id">
                Booking ID: <span class="color-blue">JC00076</span>
            </div>
            <div class="d-flex justify-content-center my-4">
                <a class="custom-home-button" href="/">Return To Home</a>
            </div>
        </div>
    </div>
</template>
<script>
import RelaxImage from '@/assets/relax.svg';
export default {
    data() {
        return {
            RelaxImage
        }
    },
}
</script>
<style lang="scss" scoped>
.custom-confirm-card{
    width:80rem;
    max-width: 90%;
    min-height:20rem;
    border-radius:1rem;
    background-color:#fff;
    box-shadow:0px 10px 30px rgba(0,0,0,0.1);
    padding: 4rem 2rem;
}
.custom-header{
    font-size: 36px;
    text-align: center;

}
.custom-paragraph {
    font-size: 28px;
    text-align: center;
    color: rgba(0,0,0,0.5);
}
.custom-image{
    position: relative;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    img{
        width: 40rem;
        height: 15rem;
        object-fit: cover;
        border-radius: 1rem;
    }
}
.custom-name {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
}
.custom-booking-id{
    opacity: 0.5;
    font-size: 18px;
    text-align: center;
    .color-blue{
        color: #4256EE;
    }
}
    .custom-home-button{
        padding: 5px 10px;
        border-radius: 4px;
        background-color: royalblue;
        color: white;
        text-decoration: none;
        border:1px solid royalblue;
        transition: 0.3s ease;
        &:hover{
            background-color: #fff;
            color: royalblue;
        }
    }
</style>