<template>
  <div class="w-100">
      <div class="custom-faq-item active">
          <div class="d-flex justify-content-between align-items-start custom-question">
            <div class="custom-faq-item-header">
                Question 1
            </div>
            <div class="arrow" style="cursor:pointer" @click="active = !active">
                <font-awesome-icon icon="chevron-down" v-if="!active" />
                <font-awesome-icon icon="chevron-up" v-else />
            </div>
          </div>
          <div class="custom-answer" :class="active ? 'active' : ''">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum accusamus possimus nulla, voluptas fugiat natus quae quaerat sapiente, sint repudiandae ea temporibus blanditiis eum, vel qui porro aliquam minus doloribus.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum accusamus possimus nulla, voluptas fugiat natus quae quaerat sapiente, sint repudiandae ea temporibus blanditiis eum, vel qui porro aliquam minus doloribus.

          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            active: false,
        }
    },
}
</script>

<style lang="scss" scoped>
    .custom-faq-item{
        width:100%;
        min-height: 3rem;
        max-height: 3rem;
        box-shadow: 0px 10px 30px -2px rgba($color: #000000, $alpha: 0.1);
        background-color: #fff;
        padding: 2rem 2rem 1rem 2rem;
        border-radius: 5px;
        overflow: hidden;
        transition: 1s ease;
        &.active{
            min-height: 4rem;
            max-height: 100rem;
            transition: 1s ease;
        }
    }
    .custom-question{
        height: 3rem;
        color:#5E5873;
        font-weight: 500;
        font-size: 15px;
    }
    .custom-answer{
        transition: 1s ease;
        height: 0px;
        display: none;
        opacity: 0;
        font-size: 14px;
        color: #6E6B7B;
        transition: 1s ease;
        &.active{
            height: auto;
            display: flex;
            opacity: 1;
            transition: 1s ease;
        }
    }
</style>