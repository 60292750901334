<template>
  <div class="vh-100 w-100">
      <NavbarCommon />
      <NavbarSmall />
      <div>
          <div class="custom-common-faqs">
              <div class="custom-header">
                  FAQs
              </div>
              <div class="d-flex justify-content-center custom-para">
                  Let us help answer the most common questions.
              </div>
              <div class="w-80 mt-4" v-for="(item, index) in items" :key="index">
                  <FAQ />
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import NavbarCommon from '@/components/NavbarCommon/NavbarCommon.vue';
import NavbarSmall from '@/components/NavbarSmall/NavbarSmall.vue';
import FAQ from '@/components/FAQ/FAQ.vue';

export default {
    components: {
        NavbarCommon,
        NavbarSmall,
        FAQ,
        items:["Lol","Hey"]
    },
}
</script>

<style lang="scss" scoped>
    .custom-header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        color: #4256EE;
        font-weight: 700;
        text-align: center;
        margin: 1rem 0 0 0;
    }
    .custom-para{
        font-size: 14px;
        color: #6E6B7B;
        text-align: center;
    }
    .w-80{
        width: 80%;
        margin: 0 auto;
    }
</style>