<template>
  <div>
    <NavbarCommon />
    <NavbarSmall />
    <div style="margin-bottom:2rem;"></div>
    <div class="card w-75 mx-auto p-2">
      <h2 class="font-butler color-black mb-2">Personal Details</h2>

      <div class="custom-user-container d-flex mt-2 w-100">
        <div class="mr-2 w-50 px-2">
          <label for="">Name</label>
          <input type="text" class="form-control" placeholder="Name" :value="name" readOnly/>
        </div>
        <div class="w-50 px-2">
          <label for="">Email</label>
          <input type="email" class="form-control" placeholder="Email" :value="email" readOnly/>
          <div class="font-small color-blue cursor-pointer">Change</div>
        </div>
      </div>

      <h2 class="font-butler color-black my-2">Security Details</h2>
      <div class="custom-user-container d-flex w-100">
        <div class="w-50 px-2">
          <label for="">Password</label>
          <input type="password" class="form-control" placeholder="Password" value="********" readOnly/>
          <div class="font-small color-blue cursor-pointer">Change</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import PencilIcon from "@/assets/icons/pencil.png";
import NavbarCommon from "@/components/NavbarCommon/NavbarCommon.vue";
import NavbarSmall from "@/components/NavbarSmall/NavbarSmall.vue";
import {getUserDetails} from '@/utils/apis.js';

export default {
  data() {
    return {
      name: "Test",
      email: "test",
      account_holder_name:"",
	    account_number:9,
	    branch_name:"",
	    ifsc_code:"",
    //   pencilIcon: PencilIcon,
    };
  },
  methods:{
      async useGetUser(){
        let response = await getUserDetails();
        console.log("PANI",response);
        this.name = response.data.user.name;
        this.email = response.data.user.email;
        
      }
  },
  mounted() {
      this.useGetUser();
  },
  components:{
    NavbarCommon,
    NavbarSmall,
  },
  props: [],
};
</script>
<style lang="scss" scoped>
.custom-image-container {
  position: relative;
  img {
    height: 7rem;
    width: 7rem;
    border-radius: 5px;
    object-fit: cover;
  }
}
.custom-document-not-submitted {
  width: 100%;
  min-height: 4rem;
  border-radius: 5px;
  background-color: #ffead5;
}
.color-blue{
    color: #00a0e9;
    cursor: pointer;
}
.font-small{
    font-size: 0.8rem;
}
</style>
