<template>
  <div class="w-100 vh-100 d-flex justify-content-center align-items-center">
    <div class="custom-container">
      <div class="heading">Contact Us</div>
      <div class="para">
        Please let us know about your problem, and we will get back to you as
        soon as possible.
      </div>
      <div class="input-container d-flex flex-wrap">
        <div class="input-block d-flex flex-column">
          <label for="">Your Name</label>
          <input
            type="text"
            name=""
            id=""
            placeholder="Enter your name"
            v-model="name"
          />
        </div>
        <div class="input-block d-flex flex-column">
          <label for="">Email Address</label>
          <input
            type="text"
            name=""
            id=""
            placeholder="Email address"
            v-model="email"
          />
        </div>
        <div class="input-block d-flex flex-column">
          <label for="">Phone number</label>
            <input type="tel" id="phone" name="phone" placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" v-model="phNo">
        </div>
      </div>
        <div class="custom-text-area d-flex flex-column">
          <label for="">Message</label>
          <textarea name="" id="" cols="30" rows="7" placeholder="Type here" v-model="message"></textarea>
          
        </div>
        <div class="custom-btn-container mt-4 d-flex justify-content-end">
            <button>
              Submit
          </button>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      phNo: "",
      message: "",
    };
  },
};
</script>
<style lang="scss" scoped>
.custom-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding-top: 8rem;
  font-family: "Montserrat", sans-serif;
}
.heading {
  font-size: 3rem;
  font-weight: 700;
  color: #222;
  margin-bottom: 1rem;
  text-align: center;
}
.para {
  font-size: 1.125rem;
  width: 45rem;
  max-width: 95%;
  text-align: center;
  margin: 0 auto;
  color: rgba(18, 17, 39, 0.56);
}
.input-container {
  width: 50rem;
  max-width: 95%;
  position: relative;
  margin: 0 auto;
  margin-top: 4rem;
  .input-block {
    width: 50%;
    position: relative;
    padding: 1rem;
    label {
      font-size: 0.9rem;
      font-weight: 600;
    }
    input {
      padding: 8px 10px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      transition: 0.3s ease;
      &:focus,
      &:active {
        outline: none;
        border: 1px solid #928da7;
      }
    }
  }
}
.custom-text-area{
  width: 50rem;
  max-width: 95%;
  position: relative;
  margin: 0 auto;
  padding: 1rem;
  label{
      font-weight: 600;

  }
  textarea{
      padding: 8px 10px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      transition: 0.3s ease;
      &:focus,
      &:active {
        outline: none;
        border: 1px solid #928da7;
      }
  }
}
.custom-btn-container{
  width: 50rem;
  max-width: 95%;
  position: relative;
  margin: 0 auto;
  padding: 1rem;
  button{
      padding: 0.7rem 1.4rem;
      border: none;
      color: #fff;
      border-radius: 5px;
        background-color: #4256EE;
  }
}
</style>
