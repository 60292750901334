<template>
  <div class="vh-100 position-relative d-flex w-100">
    <div class="w-50 position-relative h-100 d-flex align-items-center">
      <div
        class="h-90 d-flex flex-column justify-content-between"
        style="width: 80%; margin: 0 auto"
      >
        <div class="custom-item">
          <img
            src="https://wallpaperaccess.com/full/1078985.jpg"
            alt="No Image"
            style="height: 100%; width: 100%; object-fit: cover"
          />
        </div>
        <div class="custom-item custom-item-info">
          <div
            class="
              d-flex
              justify-content-center
              align-items-center
            "
            style="height:35%;border-bottom: 2px solid rgba(1, 1, 1, 0.1)"
          >
            <div>
              <div class="custom-checkin-checkout">
                <div
                  class="d-flex justify-content-center mb-2"
                  style="font-size: 24px"
                >
                  Check In
                  <span class="mx-4" style="color: #3c3ce1">|</span> Check Out
                </div>
                <div
                  class="d-flex justify-content-center mb-2"
                  style="font-size: 24px; color: rgba(0, 0, 0, 0.5)"
                >
                  18 Dec 21 <span class="mx-4">&#8594;</span> 22 Dec, 22
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 px-3 d-flex flex-column justify-content-between" style="height:65%;">
            <div>
              <div class="mb-2" style="font-size:20px;"><strong>Guests</strong></div>
              <div>2 guests ( Adults )</div>
            </div>
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="mb-2" style="font-size:20px;"><strong>Price Summary</strong></div>  
                <small style="color:#4256EE;cursor:pointer;"><b>View Details</b></small>  
              </div>
              <div style="font-size:24px; border-radius:5px;background-color:#F5F6F8;" class="p-2 d-flex justify-content-between w-full">
                <div>
                  <strong>Total</strong>
                </div>
                <div>
                  ₹ 14,999
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-50 position-relative h-100 "
      style="box-shadow: -5px 0px 30px -3px rgba(1, 1, 1, 0.1)"
    >
    <div class="d-flex flex-column justify-content-center h-100 position-relative">
      <div class="h-90 d-flex flex-column justify-content-between position-relative" style="width:80%; margin:0 auto;">
        <div style="background:pink;">
          <div style="font-size:24px;font-weight:500;">Payment</div>
          <small style="color:#828282;">All transactions are secure and encrypted.</small>
          <div class="custom-card d-flex flex-column">
            <div style="color:#828282;">UPI ( Pay Directly from your bank )</div>
            <div class="d-flex justify-content-start align-items-center w-100" style="height:3.5rem;">
              <div class="d-flex justify-content-start align-items-center w-25" >
                <div class="custom-icon justify-content-start align-items-center" id="gpay-icon" ref="gpay" @click="toggleGooglePay">
                  <div class="circle"></div>
                </div>
              </div>
              <div class="d-flex justify-content-start align-items-center">
                <img src="https://www.nicepng.com/png/detail/769-7692873_download-google-pay-logo-png.png" alt="Gpay" style="height:2.5rem;">
              </div>
            </div>
          </div>
        </div>
      <div></div>
      <div></div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  methods: {
    toggleGooglePay() {
      this.$refs.gpay.classList.toggle("active");
      this.$refs.phonepay.classList.remove("active");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-item {
  width: 100%;
  height: 48%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  &-info {
    border: 2px solid rgba($color: #000000, $alpha: 0.2);
  }
}
.h-90 {
  height: 90%;
}
.custom-checkin-checkout {
  padding: 1rem;
  border-radius: 5px;
}
.custom-card{
  width:100%;
  height:16rem;
  border-radius: 2rem;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  box-shadow: 0px 10px 30px -5px rgba($color: #000000, $alpha: 0.1);
  padding: 1rem 2rem;
  margin: 2rem 0;
}
.custom-icon{
  width:1.6rem;
  height:1.6rem;
  border-radius:50%;
  border: 2px solid #9F9F9F;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .circle{
    height: 0.8rem;
    width: 0.8rem;
    background-color: #3c3ce1;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
  }
  &.active{
    border: 2px solid #3c3ce1;
    .circle{
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
</style>
